<template>
  <InputFilteredSelect
    v-if="domainList"
    id="pzo_domain_field"
    :model-value="domain"
    :list="domainList"
    list-key="key"
    list-value="value"
    :multiple="false"
    label="Domena"
    :inverted-label="inverted"
    @update:model-value="
      (
        newValue:
          | string
          | number
          | number[]
          | string[]
          | boolean
          | boolean[]
          | undefined,
      ) => setDomain(newValue)
    "
  />
  <div v-else></div>
</template>
<script setup lang="ts">
interface Props {
  inverted?: boolean;
}

withDefaults(defineProps<Props>(), {
  inverted: false,
});

const config = useRuntimeConfig();
const { data } = await useApi("/api/domainList");
const devDomainCookie = useCookie<string | undefined>("dev-domain", {
  path: "/",
  secure: true,
  sameSite: "lax",
});

const domain = ref<string | undefined>();
const domainList = computed(() => {
  return data.value?.map((element) => {
    return { key: element, value: element };
  });
});

if (devDomainCookie.value) {
  domain.value = devDomainCookie.value;
}

function setDomain(
  newValue:
    | string
    | number
    | number[]
    | string[]
    | boolean
    | boolean[]
    | undefined,
) {
  if (typeof newValue === "string" || newValue === undefined) {
    domain.value = newValue;

    devDomainCookie.value = domain.value;
    window.location.href = config.app.baseURL;
  }
}
</script>
